import axios from "axios";

export const getAll = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/information`);
};

export const getById = id => {
  return axios.get(`${process.env.VUE_APP_API_URL}/information/${id}`);
};

export const create = payload => {
  return axios.post(`${process.env.VUE_APP_API_URL}/information`, payload);
};

export const update = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/information/${id}?_method=PUT`,
    payload
  );
};

export const remove = id => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/information/${id}`);
};
