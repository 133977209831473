import router from "@/router";
import { create, get, getAll, getFilterables, remove, update } from "@/services/modelService";
import _ from "lodash";

const state = {
  models: [],
  modelFilterables: [],
  modelEdit: null,
  modelsPage: 1,
  modelsTotal: 0,
  modelsPagesNumber: 0,
  modelsLoading: false,
  modelsError: null
};

const getters = {
  getModels: state => state.models,
  getModel: state => payload => _.first(_.filter(state.models, payload)),
  getModelFilterables: state => state.modelFilterables,
  getModelEdit: state => state.modelEdit,
  getModelsPage: state => state.modelsPage,
  getModelsTotal: state => state.modelsTotal,
  getModelsPagesNumber: state => state.modelsPagesNumber,
  getModelsError: state => state.modelsError,
  getModelsLoading: state => state.modelsLoading
};

const mutations = {
  setEditModel(state, payload) {
    state.modelEdit = payload;
  },
  setModels(state, payload) {
    state.models = _.get(payload, "data");
    state.modelsPage = _.get(payload, "current_page");
    state.modelsTotal = _.get(payload, "total");
    state.modelsPagesNumber = _.get(payload, "last_page");
  },
  resetModels(state) {
    state.models = [];
    state.modelsPage = 1;
    state.modelsTotal = 0;
    state.modelsPagesNumber = 0;
  },
  setModelsError(state, payload) {
    state.modelsError = payload;
  },
  setModelsLoading(state, payload) {
    state.modelsLoading = payload;
  },
  setModelFilterables(state, payload) {
    state.modelFilterables = payload;
  }
};

const actions = {
  async setModels({ commit }, payload) {
    try {
      commit("resetModels");
      commit("setModelsLoading", true);
      const { data } = await getAll(_.get(payload, "model"), {
        q: _.get(payload, "q") || "",
        filters: _.get(payload, "filters") || {},
        page: _.get(payload, "page", 1),
        perPage: _.get(payload, "perPage", 100),
        sortBy: _.get(payload, "sortBy") || "",
        sortDesc: Number(_.get(payload, "sortDesc", 0))
      });
      commit("setModels", data);
    } catch (error) {
      console.error("setModels", error);
      commit("setModels", []);
      commit("setModelsError", error);
    }

    commit("setModelsLoading", false);
  },
  async setEditModel({ commit }, { model, id }) {
    try {
      commit("setModelsLoading", true);
      const { data } = await get(model, id);
      commit("setEditModel", data);
    } catch (error) {
      console.error("setEditModel", error);
      commit("setEditModel", null);
      commit("setModelsError", error);
    }

    commit("setModelsLoading", false);
  },
  async createModel({ commit, dispatch }, { model, payload }) {
    try {
      commit("setModelsLoading", true);
      await create(model, payload);
      dispatch("showSnackbar", `${_.startCase(model)} created.`);
      router.push(`/app/models/${model}`);
    } catch (error) {
      console.error("DEBUG: [createModel]", error);
      commit("setModelsError", error);
    }

    commit("setModelsLoading", false);
  },
  async updateModel({ commit, dispatch }, { model, id, payload }) {
    try {
      commit("setModelsLoading", true);
      await update(model, id, payload);
      commit("setEditModel", null);
      dispatch("showSnackbar", `${_.startCase(model)} updated.`);
      router.push(`/app/models/${model}`);
    } catch (error) {
      console.error(error);
      commit("setModelsError", error);
    }

    commit("setModelsLoading", false);
  },
  async removeModel({ commit, dispatch }, { model, id, pagination }) {
    try {
      commit("setModelsLoading", true);
      const { data } = await remove(model, id, pagination);
      commit("setModels", data);
      dispatch("showSnackbar", `${_.startCase(model)} deleted.`);
    } catch (error) {
      commit("setModelsError");
    }

    commit("setModelsLoading", false);
  },
  async setModelFilterables({ commit, dispatch }, { model }) {
    commit("setModelsLoading", true);
    try {
      commit("setModelFilterables", []);
      const { data } = await getFilterables(model);
      commit("setModelFilterables", data);
    } catch (error) {
      commit("setModelsError");
    }
    commit("setModelsLoading", false);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
