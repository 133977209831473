import axios from "axios";

export const getAll = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/self-test`);
};

export const update = (id, payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/self-test/${id}?_method=PUT`, payload);
};

export const remove = id => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/self-test/${id}`);
};
