import router from "@/router";
import { getAll, remove, update } from "@/services/selfTestService";
import _ from "lodash";

const state = {
  selfTest: [],
  selfTestLoading: false
};

const getters = {
  getSelfTests: state => state.selfTest,
  getSelfTest: state => id =>
    _.first(_.filter(state.selfTest, hs => hs.id == id)),
  getSelfTestLoading: state => state.selfTestLoading
};

const mutations = {
  setSelfTest(state, payload) {
    state.selfTest = payload;
  },
  setSelfTestLoading(state, payload) {
    state.selfTestLoading = payload;
  }
};

const actions = {
  async fetchSelfTest({ commit }) {
    try {
      const { data } = await getAll();
      commit("setSelfTest", data);
    } catch (error) {
      console.error("DEBUG: [fetchSelfTest]", error);
    }
  },
  async updateSelfTest({ commit, dispatch }, { id, payload }) {
    try {
      commit("setSelfTestLoading", true);
      await update(id, payload);
      await dispatch("fetchSelfTest");
      dispatch("showSnackbar", `Help search updated.`);
      router.push(`/app/self-test`);
    } catch (error) {
      console.error("DEBUG: [updateSelfTest]", error);
    }

    commit("setSelfTestLoading", false);
  },
  async removeSelfTest({ commit, dispatch }, id) {
    try {
      commit("setSelfTestLoading", true);
      const { data } = await remove(id);
      commit("setSelfTest", data);
      dispatch("showSnackbar", `Help search deleted.`);
    } catch (error) {
      console.error("DEBUG: [removeSelfTest]", error);
    }

    commit("setSelfTestLoading", false);
  },
  setSelfTestLoading({ commit }, payload) {
    commit("setSelfTestLoading", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
