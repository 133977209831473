import axios from "axios";

export const login = payload => {
  return axios.post(`${process.env.VUE_APP_API_URL}/users/login`, payload);
};

export const rehydrateToken = () => {
  return axios.post(`${process.env.VUE_APP_API_URL}/users/rehydrate`);
};

export const getUserTypes = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/users/types`);
};

export const resetPassword = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/users/reset-password`, payload);
};

export const validateResetPasswordLink = (payload) => {
  return axios.get(`${process.env.VUE_APP_API_URL}/users/reset-password/validate-link`, {
    params: payload
  });
};

export const forgotPassword = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/users/forgot-password`, payload);
};
