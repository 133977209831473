import router from "@/router";
import { create, getAll, remove, update } from "@/services/helpSearchService";
import { showValidationErrors } from "@/shared/utils";
import _ from "lodash";

const state = {
  helpSearch: [],
  helpSearchLoading: false
};

const getters = {
  getHelpSearches: state => state.helpSearch,
  getHelpSearch: state => id =>
    _.first(_.filter(state.helpSearch, hs => hs.id == id)),
  getHelpSearchLoading: state => state.helpSearchLoading
};

const mutations = {
  setHelpSearch(state, payload) {
    state.helpSearch = payload;
  },
  setHelpSearchLoading(state, payload) {
    state.helpSearchLoading = payload;
  }
};

const actions = {
  async fetchHelpSearch({ commit }) {
    try {
      const { data } = await getAll();
      commit("setHelpSearch", data);
    } catch (error) {
      console.error("DEBUG: [fetchHelpSearch]", error);
    }
  },
  async createHelpSearch({ commit, dispatch }, payload) {
    try {
      commit("setHelpSearchLoading", true);
      await create(payload);
      await dispatch("fetchHelpSearch");
      dispatch("showSnackbar", `Help search created.`);
      router.push(`/app/help-search`);
    } catch (error) {
      console.error("DEBUG: [createHelpSearch]", error);
      showValidationErrors(error.response, dispatch);
    }

    commit("setHelpSearchLoading", false);
  },
  async updateHelpSearch({ commit, dispatch }, { id, payload }) {
    try {
      commit("setHelpSearchLoading", true);
      await update(id, payload);
      await dispatch("fetchHelpSearch");
      dispatch("showSnackbar", `Help search updated.`);
      router.push(`/app/help-search`);
    } catch (error) {
      console.error("DEBUG: [updateHelpSearch]", error);
      showValidationErrors(error.response, dispatch);
    }

    commit("setHelpSearchLoading", false);
  },
  async removeHelpSearch({ commit, dispatch }, id) {
    try {
      commit("setHelpSearchLoading", true);
      const { data } = await remove(id);
      commit("setHelpSearch", data);
      dispatch("showSnackbar", `Help search deleted.`);
    } catch (error) {
      console.error("DEBUG: [removeHelpSearch]", error);
    }

    commit("setHelpSearchLoading", false);
  },
  setHelpSearchLoading({ commit }, payload) {
    commit("setHelpSearchLoading", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
