import axios from "axios";

export const getAll = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/pages`);
};

export const getPagesForUser = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/pages/user-accessible`);
};

export const create = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/pages`, payload);
};

export const update = (id, payload) => {
  return axios.put(`${process.env.VUE_APP_API_URL}/pages/${id}`, payload);
};

export const remove = (id, payload = null) => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/pages/${id}`, {
    params: payload
  });
};

export const reorder = (order) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/pages/reorder`, { order });
};
