// Vuetify Documentation https://vuetifyjs.com

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { themePreset } from "../../src/themeConfig";

const vuetify = new Vuetify({
  theme: themePreset.theme,
  rtl: themePreset.rtl
});

Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: "mdi" });

export default vuetify;
