import Vue from "vue";
import Router from "vue-router";
import multiguard from "vue-router-multiguard";
import authenticate from "../guards/authenticate";
import hasCrudPermission from "../guards/hasCrudPermission";
import resetPassword from "../guards/resetPassword";
import unAuthenticated from "../guards/unAuthenticated";
import accessiblePages from "../resolvers/accessiblePages";
import articleList from "../resolvers/articleList";
import blogList from "../resolvers/blogList";
import eventList from "../resolvers/eventList";
import helpSearchList from "../resolvers/helpSearchList";
import informationList from "../resolvers/informationList";
import informationPages from "../resolvers/informationPages";
import modelEdit from "../resolvers/modelEdit";
import modelList from "../resolvers/modelList";
import podcastList from "../resolvers/podcastList";
import selfTestList from "../resolvers/selfTestList";
import templateList from "../resolvers/templateList";
import userTypes from "../resolvers/userTypes";
import { MODEL_ACTION } from "../shared/constants";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/auth",
      component: () => import("@/views/auth/Index"),
      redirect: "/auth/sign-in",
      children: [
        {
          path: "sign-in",
          name: "sign-in",
          beforeEnter: unAuthenticated,
          component: () => import("@/views/auth/SignIn")
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          beforeEnter: unAuthenticated,
          component: () => import("@/views/auth/ForgotPassword")
        },
        {
          path: "reset-password",
          name: "reset-password",
          beforeEnter: multiguard([unAuthenticated, resetPassword]),
          component: () => import("@/views/auth/ResetPassword")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      beforeEnter: multiguard([authenticate, accessiblePages]),
      redirect: "/app/dashboard",
      children: [
        {
          path: "/app/models/:model",
          component: () => import("@/views/app/models/Index"),
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/models/List"),
              beforeEnter: modelList
            },
            {
              path: "create",
              component: () => import("@/views/app/models/Form"),
              beforeEnter: hasCrudPermission,
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/models/Form"),
              beforeEnter: multiguard([modelEdit, hasCrudPermission]),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/page",
          component: () => import("@/views/app/pages/Index"),
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/pages/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/pages/Form"),
              beforeEnter: userTypes,
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/pages/Form"),
              beforeEnter: userTypes,
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/event",
          component: () => import("@/views/app/events/Index"),
          beforeEnter: eventList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/events/List")
            }
          ]
        },
        {
          path: "/app/template",
          component: () => import("@/views/app/templates/Index"),
          beforeEnter: templateList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/templates/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/templates/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/templates/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/publish",
          component: () => import("@/views/app/publish/Index")
        },
        {
          path: "/app/dashboard",
          component: () => import("@/views/app/dashboard/Index")
        },
        {
          path: "/app/blog",
          component: () => import("@/views/app/blogs/Index"),
          beforeEnter: blogList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/blogs/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/blogs/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/blogs/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/podcast",
          component: () => import("@/views/app/podcasts/Index"),
          beforeEnter: podcastList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/podcasts/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/podcasts/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/podcasts/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/article",
          component: () => import("@/views/app/articles/Index"),
          beforeEnter: articleList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/articles/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/articles/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/articles/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/help-search",
          component: () => import("@/views/app/help-search/Index"),
          beforeEnter: helpSearchList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/help-search/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/help-search/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/help-search/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/self-test",
          component: () => import("@/views/app/self-test/Index"),
          beforeEnter: selfTestList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/self-test/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/self-test/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/self-test/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/information",
          component: () => import("@/views/app/information/Index"),
          beforeEnter: informationList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/information/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/information/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/information/Form"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            },
            {
              path: ":id/pages",
              component: () => import("@/views/app/information/PagesForm"),
              beforeEnter: informationPages
            }
          ]
        }
      ]
    },
    {
      path: "*",
      redirect: "/app/dashboard"
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.path) {
    store.dispatch("changeThemeLoadingState", true);
  }

  next();
});

router.afterEach(() => {
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  store.dispatch("changeThemeLoadingState", false);
});

export default router;
