import router from "@/router";
import { create, getAll, preview, remove, update } from "@/services/podcastService";
import _ from "lodash";

const state = {
  podcasts: [],
  podcastsLoading: false,
  podcastPreviewLoading: false
};

const getters = {
  getPodcasts: state => state.podcasts,
  getPodcast: state => id => _.first(_.filter(state.podcasts, podcast => podcast.id == id)),
  getPodcastsLoading: state => state.podcastsLoading,
  getPodcastPreviewLoading: state => state.podcastPreviewLoading
};

const mutations = {
  setPodcasts(state, payload) {
    state.podcasts = payload;
  },
  setPodcastsLoading(state, payload) {
    state.podcastsLoading = payload;
  },
  setPodcastPreviewLoading(state, payload) {
    state.podcastPreviewLoading = payload;
  }
};

const actions = {
  async fetchPodcasts({ commit }) {
    try {
      const { data } = await getAll();
      commit("setPodcasts", data);
    } catch (error) {
      console.error("DEBUG: [fetchPodcasts]", error);
    }
  },
  async createPodcast({ commit, dispatch }, payload) {
    try {
      commit("setPodcastsLoading", true);
      await create(payload);
      await dispatch("fetchPodcasts");
      dispatch("showSnackbar", `Article created.`);
      router.push(`/app/podcast`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [createPodcast]", error);
    }

    commit("setPodcastsLoading", false);
  },
  async updatePodcast({ commit, dispatch }, { id, payload }) {
    try {
      commit("setPodcastsLoading", true);
      await update(id, payload);
      await dispatch("fetchPodcasts");
      dispatch("showSnackbar", `Article updated.`);
      router.push(`/app/podcast`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [updatePodcast]", error);
    }

    commit("setPodcastsLoading", false);
  },
  async removePodcast({ commit, dispatch }, id) {
    try {
      commit("setPodcastsLoading", true);
      const { data } = await remove(id);
      commit("setPodcasts", data);
      dispatch("showSnackbar", `Article deleted.`);
    } catch (error) {
      console.error("DEBUG: [removePodcast]", error);
    }

    commit("setPodcastsLoading", false);
  },
  async previewPodcast({ commit, dispatch }, { id, payload, lang }) {
    try {
      commit("setPodcastPreviewLoading", true);
      const { data } = await preview(id, payload, lang);
      commit("setPodcastPreviewLoading", false);
      return data;
    } catch (error) {
      commit("setPodcastPreviewLoading", false);
      console.error("DEBUG: [removePodcast]", error);
    }

  },
  setPodcastsLoading({ commit }, payload) {
    commit("setPodcastsLoading", payload);
  },
  setPodcastPreviewLoading({ commit }, payload) {
    commit("setPodcastPreviewLoading", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
