import axios from "axios";

export const getAll = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/help-search`);
};

export const getGpsDataByZipcode = (zipcode) => {
  return axios.get(`${process.env.VUE_APP_API_URL}/help-search/gps/${zipcode}`);
};

export const getRelationshipData = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/help-search/relationships`);
};

export const create = payload => {
  return axios.post(`${process.env.VUE_APP_API_URL}/help-search`, payload);
};

export const update = (id, payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/help-search/${id}?_method=PUT`, payload);
};

export const remove = id => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/help-search/${id}`);
};
