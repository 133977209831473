import router from "@/router";
import {
  create as createPage,
  getAll as getAllPages,
  remove as removePage,
  reorder,
  update as updatePage
} from "@/services/informationPageService";
import { create, getAll, remove, update } from "@/services/informationService";
import { filter, first, get, map } from "lodash";

const state = {
  selectedInformation: {},
  informationList: [],
  informationPages: [],
  informationLoading: false,
  informationPageLoading: false
};

const getters = {
  getInformation: state => id =>
    first(filter(state.informationList, info => info.id == id)),
  getSelectedInformation: state => state.selectedInformation,
  getInformationList: state => state.informationList,
  getInformationPages: state => state.informationPages,
  getInformationLoading: state => state.informationLoading,
  getInformationPageLoading: state => state.informationPageLoading
};

const mutations = {
  setSelectedInformation(state, payload) {
    state.selectedInformation = payload;
  },
  setInformationList(state, payload) {
    state.informationList = payload;
  },
  setInformationLoading(state, payload) {
    state.informationLoading = payload;
  },
  setInformationPages(state, payload) {
    state.informationPages = payload;
  },
  setInformationPageLoading(state, payload) {
    state.informationPageLoading = payload;
  }
};

const actions = {
  async setSelectedInformation({ commit, dispatch, state }, id) {
    try {
      const selected = first(
        filter(state.informationList, info => info.id == id)
      );
      commit("setSelectedInformation", selected);
      dispatch("fetchInformationPages", id);
    } catch (error) {
      console.error("DEBUG: [setSelectedInformation]", error);
    }
  },
  async fetchInformationList({ commit }) {
    try {
      const { data } = await getAll();
      commit("setInformationList", data);
    } catch (error) {
      console.error("DEBUG: [fetchInformationList]", error);
    }
  },
  async createInformation({ commit, dispatch }, payload) {
    try {
      commit("setInformationLoading", true);
      await create(payload);
      await dispatch("fetchInformationList");
      dispatch("showSnackbar", `Information created.`);
      router.push(`/app/information`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [createInformation]", error);
    }

    commit("setInformationLoading", false);
  },
  async updateInformation({ commit, dispatch }, { id, payload }) {
    try {
      commit("setInformationLoading", true);
      await update(id, payload);
      await dispatch("fetchInformationList");
      dispatch("showSnackbar", `Information updated.`);
      router.push(`/app/information`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [updateInformation]", error);
    }

    commit("setInformationLoading", false);
  },
  async removeInformation({ commit, dispatch }, id) {
    try {
      commit("setInformationLoading", true);
      const { data } = await remove(id);
      commit("setInformationList", data);
      dispatch("showSnackbar", `Information deleted.`);
    } catch (error) {
      console.error("DEBUG: [removeInformation]", error);
    }

    commit("setInformationLoading", false);
  },
  async fetchInformationPages({ commit }, id = null) {
    try {
      const { data } = await getAllPages(id);
      commit("setInformationPages", data);
    } catch (error) {
      console.error("DEBUG: [fetchInformationPages]", error);
    }
  },
  async createInformationPage({ commit, dispatch, state }, payload) {
    try {
      commit("setInformationPageLoading", true);
      await createPage(payload);
      await dispatch(
        "fetchInformationPages",
        get(state, "selectedInformation.id")
      );
      dispatch("showSnackbar", `Information page created.`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [createInformationPage]", error);
    }

    commit("setInformationPageLoading", false);
  },
  async updateInformationPage({ commit, dispatch, state }, { id, payload }) {
    try {
      commit("setInformationPageLoading", true);
      await updatePage(id, payload);
      await dispatch(
        "fetchInformationPages",
        get(state, "selectedInformation.id")
      );
      dispatch("showSnackbar", `Information page updated.`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [updateInformationPage]", error);
    }

    commit("setInformationPageLoading", false);
  },
  async removeInformationPage({ commit, dispatch, state }, id) {
    try {
      commit("setInformationPageLoading", true);
      await removePage(id);
      await dispatch(
        "fetchInformationPages",
        get(state, "selectedInformation.id")
      );
      dispatch("showSnackbar", `Information page deleted.`);
    } catch (error) {
      console.error("DEBUG: [removeInformationPage]", error);
    }

    commit("setInformationPageLoading", false);
  },
  async reorderInformationPages({ commit, state }) {
    try {
      commit("setInformationPageLoading", true);
      await reorder({
        items: map(state.informationPages, (item, index) => ({
          id: item.id,
          order: index
        }))
      });
    } catch (error) {
      console.error("DEBUG: [reorderInformationPages]", error);
    }

    commit("setInformationPageLoading", false);
  },
  setInformationLoading({ commit }, payload) {
    commit("setInformationLoading", payload);
  },
  setInformationPageLoading({ commit }, payload) {
    commit("setInformationPageLoading", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
