import store from "@/store";

export default async (to, from, next) => {
  const model =  _.get(to, "params.model");
  await store.dispatch("setModels", {
    model,
    page: 1,
    perPage: 100
  });
  next();
};
