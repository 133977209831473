import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import articles from "./modules/articles";
import auth from "./modules/auth";
import blogs from "./modules/blogs";
import compactSidebar from "./modules/compactSidebar";
import events from "./modules/events";
import helpSearches from "./modules/helpSearches";
import information from "./modules/information";
import largeSidebar from "./modules/largeSidebar";
import models from "./modules/models";
import pages from "./modules/pages";
import podcasts from "./modules/podcasts";
import selfTests from "./modules/selfTests";
import snackbar from "./modules/snackbar";
import templates from "./modules/templates";
import themeConfig from "./modules/themeConfig";
import userTypes from "./modules/userTypes";
import verticalSidebar from "./modules/verticalSidebar";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    auth: {
      loggedInUser: state.auth.loggedInUser,
      token: state.auth.token
    }
  }),
  key: "eyestone-admin-v2"
});

export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    themeConfig,
    auth,
    verticalSidebar,
    userTypes,
    pages,
    models,
    blogs,
    articles,
    helpSearches,
    templates,
    selfTests,
    snackbar,
    events,
    podcasts,
    information
  },
  plugins: [vuexLocal.plugin]
});
