import store from "@/store";

export default async (to, from, next) => {
  try {
    await store.dispatch("fetchTemplates");
    next();
  } catch (error) {
    console.error('DEBUG: [fetchTemplates]', error);
  }
};
