import { objToUrlParam } from "@/shared/utils";
import axios from "axios";

export const getAll = (model, params) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/models/${model}`, {
      params
    }
  );
};

export const get = (model, id) => {
  return axios.get(`${process.env.VUE_APP_API_URL}/models/${model}/${id}`);
};

export const create = (model, payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/models/${model}`, payload);
};

export const update = (model, id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/models/${model}/${id}?_method=PUT`,
    payload
  );
};

export const remove = (model, id, pagination) => {
  return axios.delete(
    `${process.env.VUE_APP_API_URL}/models/${model}/${id}?${objToUrlParam(
      pagination
    )}`
  );
};

export const getFilterables = (model) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/models/${model}/filterables`
  );
};
