import { getAll } from "@/services/userTypeService";
import _ from "lodash";

const state = {
  userTypes: null
};

const getters = {
  getUserTypes: state => state.userTypes,
  getNonAdminUserTypes: state =>
    _.chain(state.userTypes)
      .filter(type => type.id !== 1)
      .map(type => type.name)
      .value()
};

const mutations = {
  setUserTypes(state, payload) {
    state.userTypes = payload;
  }
};

const actions = {
  async setUserTypes({ commit }) {
    try {
      const { data } = await getAll();
      commit("setUserTypes", data);
    } catch (error) {
      console.error(error);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
