import axios from "axios";

export const getAll = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/templates`);
};

export const create = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/templates`, payload);
};

export const update = (id, payload) => {
  return axios.put(`${process.env.VUE_APP_API_URL}/templates/${id}`, payload);
};

export const remove = (id, payload = null) => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/templates/${id}`, {
    params: payload
  });
};

export const generate = (preview = false) => {
  return axios.get(`${process.env.VUE_APP_API_URL}/commands/publish`, {
    params: { preview: preview ? 1 : 0 }
  });
};
