import {
  create,
  getPagesForUser,
  remove,
  reorder,
  update
} from "@/services/pageService";
import { getPageFieldsHeader } from "@/shared/utils";
import _ from "lodash";

const state = {
  pages: [],
  pagesloading: false,
  pagesError: null
};

const getters = {
  getPages: state => state.pages,
  getPage: state => ({ model = null, id = null }) => {
    return _.first(
      _.filter(state.pages, p => (id ? p.id === id : p.to === model))
    );
  },
  getPageFields: state => model =>
    _.get(_.first(_.filter(state.pages, p => p.to === model)), "fields", []),
  getPageFieldsHeader: state => model =>
    getPageFieldsHeader(state.pages, model),
  getPageLoading: state => state.pagesloading,
  getPageError: state => state.pagesError
};

const mutations = {
  setPages(state, payload) {
    state.pages = payload;
  },
  setPageLoading(state, payload) {
    state.pagesloading = payload;
  },
  setPageError(state, payload) {
    state.pagesError = payload;
  }
};

const actions = {
  async setPages({ commit }) {
    try {
      const { data } = await getPagesForUser();
      commit("setPages", data);
    } catch (error) {
      console.error("DEBUG: [setPages]", error);
      commit("setPageError", error);
    }
  },
  async createPage({ dispatch }, payload) {
    const url = await create(payload);
    await dispatch("setPages");
    return url;
  },
  async updatePage({ dispatch }, { id, payload }) {
    const url = await update(id, payload);
    await dispatch("setPages");
    return url;
  },
  async removePage({ dispatch }, { id, payload }) {
    await remove(id, payload);
    await dispatch("setPages");
  },
  async reorderPage({ dispatch }, payload) {
    await reorder(payload);
    await dispatch("setPages");
  },
  setPageLoading({ commit }, payload) {
    commit("setPageLoading", payload);
  },
  setPageError({ commit }, payload) {
    commit("setPageError", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
