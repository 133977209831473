import { validateResetPasswordLink } from "@/services/authService";
import store from "@/store";
import _ from "lodash";

export default (to, from, next) => {
  if (!_.get(to, 'query.email') || !_.get(to, 'query.hash')) {
    next("/auth/sign-in");
  }

  validateResetPasswordLink(_.pick(to.query, ['email', 'hash']))
    .then(() => next())
    .catch((error) => {
      setTimeout(() => {
        store.dispatch("showSnackbar", _.get(error, "data"));
      }, 300);
      next("/auth/sign-in");
    })
};
