import router from "@/router";
import { forgotPassword, login, resetPassword } from "@/services/authService";
import { USER_TYPES } from "@/shared/constants";
import _ from "lodash";

const state = {
  loggedInUser: null,
  token: null,
  authLoading: false,
  authError: null
};

const getters = {
  loggedInUser: state => state.loggedInUser,
  isAdminUser: state => _.get(state.loggedInUser, "type") === USER_TYPES.ADMIN,
  getAuthLoading: state => state.authLoading,
  getAuthError: state => state.authError,
  getToken: state => state.token
};

const mutations = {
  setUser(state, payload) {
    state.loggedInUser = _.get(payload, "user");
    state.token = _.get(payload, "token");
    state.authLoading = false;
    state.authError = null;
  },
  setLogout(state) {
    state.loggedInUser = null;
    state.token = null;
    state.authLoading = false;
    state.authError = null;
  },
  setLoading(state, payload) {
    state.authLoading = payload;
    state.authError = null;
  },
  setError(state, payload) {
    state.authError = payload;
    state.loggedInUser = null;
    state.authLoading = false;
  },
  clearError(state) {
    state.authError = null;
  }
};

const actions = {
  async login({ commit }, payload) {
    commit("clearError");
    commit("setLoading", true);

    try {
      const { data } = await login(payload);
      commit("setUser", data);
      router.push('/');
    } catch (error) {
      console.error(error);
      commit("setError", _.get(error, "data"));
    }
  },
  async forgotPassword({ commit }, payload) {
    commit("clearError");
    commit("setLoading", true);

    try {
      const response = await forgotPassword(payload);
      commit("setLoading", false);
      return response;
    } catch (error) {
      commit("setError", _.get(error, "data"));
    }
  },
  async resetPassword({ commit }, payload) {
    commit("clearError");
    commit("setLoading", true);

    try {
      const response = await resetPassword(payload);
      commit("setLoading", false);
      return response;
    } catch (error) {
      commit("setError", _.get(error, "data"));
    }
  },
  signOut({ commit }) {
    commit("setLogout");
    router.push('/auth/sign-in');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
