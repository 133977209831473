import router from "@/router";
import { create, getAll, preview, remove, update } from "@/services/articleService";
import _ from "lodash";

const state = {
  articles: [],
  articlesLoading: false,
  articlePreviewLoading: false
};

const getters = {
  getArticles: state => state.articles,
  getArticle: state => id => _.first(_.filter(state.articles, article => article.id == id)),
  getArticlesLoading: state => state.articlesLoading,
  getArticlePreviewLoading: state => state.articlePreviewLoading
};

const mutations = {
  setArticles(state, payload) {
    state.articles = payload;
  },
  setArticlesLoading(state, payload) {
    state.articlesLoading = payload;
  },
  setArticlePreviewLoading(state, payload) {
    state.articlePreviewLoading = payload;
  }
};

const actions = {
  async fetchArticles({ commit }) {
    try {
      const { data } = await getAll();
      commit("setArticles", data);
    } catch (error) {
      console.error("DEBUG: [fetchArticles]", error);
    }
  },
  async createArticle({ commit, dispatch }, payload) {
    try {
      commit("setArticlesLoading", true);
      await create(payload);
      await dispatch("fetchArticles");
      dispatch("showSnackbar", `Article created.`);
      router.push(`/app/article`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [createArticle]", error);
    }

    commit("setArticlesLoading", false);
  },
  async updateArticle({ commit, dispatch }, { id, payload }) {
    try {
      commit("setArticlesLoading", true);
      await update(id, payload);
      await dispatch("fetchArticles");
      dispatch("showSnackbar", `Article updated.`);
      router.push(`/app/article`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [updateArticle]", error);
    }

    commit("setArticlesLoading", false);
  },
  async removeArticle({ commit, dispatch }, id) {
    try {
      commit("setArticlesLoading", true);
      const { data } = await remove(id);
      commit("setArticles", data);
      dispatch("showSnackbar", `Article deleted.`);
    } catch (error) {
      console.error("DEBUG: [removeArticle]", error);
    }

    commit("setArticlesLoading", false);
  },
  async previewArticle({ commit, dispatch }, { id, payload, lang }) {
    try {
      commit("setArticlePreviewLoading", true);
      const { data } = await preview(id, payload, lang);
      commit("setArticlePreviewLoading", false);
      return data;
    } catch (error) {
      commit("setArticlePreviewLoading", false);
      console.error("DEBUG: [removeArticle]", error);
    }

  },
  setArticlesLoading({ commit }, payload) {
    commit("setArticlesLoading", payload);
  },
  setArticlePreviewLoading({ commit }, payload) {
    commit("setArticlePreviewLoading", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
