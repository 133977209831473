const state = {
  snack: ""
};

const getters = {
  getSnackbar: state => state.snack
};

const mutations = {
  setSnackbar(state, payload) {
    state.snack = payload;
  }
};

const actions = {
  showSnackbar({ commit }, payload) {
    commit("setSnackbar", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
