import router from "@/router";
import { create, getAll, preview, remove, update } from "@/services/blogService";
import _ from "lodash";

const state = {
  blogs: [],
  blogsLoading: false,
  blogPreviewLoading: false
};

const getters = {
  getBlogs: state => state.blogs,
  getBlog: state => id => _.first(_.filter(state.blogs, blog => blog.id == id)),
  getBlogsLoading: state => state.blogsLoading,
  getBlogPreviewLoading: state => state.blogPreviewLoading
};

const mutations = {
  setBlogs(state, payload) {
    state.blogs = payload;
  },
  setBlogsLoading(state, payload) {
    state.blogsLoading = payload;
  },
  setBlogPreviewLoading(state, payload) {
    state.blogPreviewLoading = payload;
  }
};

const actions = {
  async fetchBlogs({ commit }) {
    try {
      const { data } = await getAll();
      commit("setBlogs", data);
    } catch (error) {
      console.error("DEBUG: [fetchBlogs]", error);
    }
  },
  async createBlog({ commit, dispatch }, payload) {
    try {
      commit("setBlogsLoading", true);
      await create(payload);
      await dispatch("fetchBlogs");
      dispatch("showSnackbar", `Article created.`);
      router.push(`/app/blog`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [createBlog]", error);
    }

    commit("setBlogsLoading", false);
  },
  async updateBlog({ commit, dispatch }, { id, payload }) {
    try {
      commit("setBlogsLoading", true);
      await update(id, payload);
      await dispatch("fetchBlogs");
      dispatch("showSnackbar", `Article updated.`);
      router.push(`/app/blog`);
    } catch (error) {
      dispatch("showSnackbar", error.response.data);
      console.error("DEBUG: [updateBlog]", error);
    }

    commit("setBlogsLoading", false);
  },
  async removeBlog({ commit, dispatch }, id) {
    try {
      commit("setBlogsLoading", true);
      const { data } = await remove(id);
      commit("setBlogs", data);
      dispatch("showSnackbar", `Article deleted.`);
    } catch (error) {
      console.error("DEBUG: [removeBlog]", error);
    }

    commit("setBlogsLoading", false);
  },
  async previewBlog({ commit, dispatch }, { id, payload, lang }) {
    try {
      commit("setBlogPreviewLoading", true);
      const { data } = await preview(id, payload, lang);
      commit("setBlogPreviewLoading", false);
      return data;
    } catch (error) {
      commit("setBlogPreviewLoading", false);
      console.error("DEBUG: [removeBlog]", error);
    }

  },
  setBlogsLoading({ commit }, payload) {
    commit("setBlogsLoading", payload);
  },
  setBlogPreviewLoading({ commit }, payload) {
    commit("setBlogPreviewLoading", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
