import router from "@/router";
import { create, generate, getAll, remove, update } from "@/services/templateService";
import _ from "lodash";

const state = {
  templates: [],
  templatesLoading: false,
  error: null
};

const getters = {
  getTemplates: state => state.templates,
  getTemplate: state => id =>
    _.first(_.filter(state.templates, hs => hs.id == id)),
  getTemplatesExcluding: state => id =>
    _.filter(state.templates, hs => hs.id != id && !hs.is_page),
  getTemplateLoading: state => state.templatesLoading,
  getTemplateError: state => state.error
};

const mutations = {
  setTemplates(state, payload) {
    state.templates = payload;
  },
  setTemplateLoading(state, payload) {
    state.templatesLoading = payload;
  },
  setTemplateError(state, payload) {
    state.error = payload;
  }
};

const actions = {
  async fetchTemplates({ commit }) {
    try {
      const { data: payload } = await getAll();
      commit("setTemplates", payload);
    } catch (error) {
      console.error("DEBUG: [setTemplates]", error);
    }
  },
  async generateTemplate({ commit, dispatch }, preview = false) {
    try {
      commit("setTemplateLoading", true);
      commit("setTemplateError", null);
      await generate(preview);
    } catch (error) {
      commit("setTemplateError", error);
      dispatch("showSnackbar", `Oops! Something went wrong.`);
      console.error("DEBUG: [generateTemplate]", error);
    }

    commit("setTemplateLoading", false);
  },
  async createTemplate({ commit, dispatch }, payload) {
    try {
      commit("setTemplateLoading", true);
      const { data: id } = await create(payload);
      await dispatch("fetchTemplates");
      dispatch("showSnackbar", `Template created.`);
      router.push(`/app/template`);
    } catch (error) {
      dispatch("showSnackbar", `Oops! Something went wrong.`);
      console.error("DEBUG: [createTemplate]", error);
    }

    commit("setTemplateLoading", false);
  },
  async updateTemplate({ commit, dispatch }, { id, payload }) {
    try {
      commit("setTemplateLoading", true);
      await update(id, payload);
      await dispatch("fetchTemplates");
      dispatch("showSnackbar", `Template updated.`);
      router.push(`/app/template`);
    } catch (error) {
      dispatch("showSnackbar", `Oops! Something went wrong.`);
      console.error("DEBUG: [updateTemplate]", error);
    }

    commit("setTemplateLoading", false);
  },
  async removeTemplate({ commit, dispatch }, id) {
    try {
      commit("setTemplateLoading", true);
      const { data } = await remove(id);
      commit("setTemplates", data);
      dispatch("showSnackbar", `Template deleted.`);
    } catch (error) {
      dispatch("showSnackbar", `Oops! Something went wrong.`);
      console.error("DEBUG: [removeTemplate]", error);
    }

    commit("setTemplateLoading", false);
  },
  setTemplateLoading({ commit }, payload) {
    commit("setTemplateLoading", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
