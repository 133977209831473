import { getAll } from "@/services/eventService";
import _ from "lodash";

const state = {
  events: null,
  eventsPage: 1,
  eventsTotal: 0,
  eventsPagesNumber: 0,
  eventsLoading: false
};

const getters = {
  getEvents: state => state.events,
  getEventsPage: state => state.eventsPage,
  getEventsTotal: state => state.eventsTotal,
  getEventsPagesNumber: state => state.eventsPagesNumber,
  getEventsLoading: state => state.eventsLoading
};

const mutations = {
  setEvents(state, payload) {
    state.events = _.get(payload, "data");
    state.eventsPage = _.get(payload, "current_page");
    state.eventsTotal = _.get(payload, "total");
    state.eventsPagesNumber = _.get(payload, "last_page");
  },
  setEventsLoading(state, payload) {
    state.eventsLoading = payload;
  }
};

const actions = {
  async setEvents({ commit }, payload) {
    try {
      commit("setEventsLoading", true);
      const { data } = await getAll({
        page: _.get(payload, "page", 1),
        perPage: _.get(payload, "perPage", 10),
        type: _.get(payload, "type") || "",
        dates: JSON.stringify(_.get(payload, "dates", []))
      });
      commit("setEvents", data);
    } catch (error) {
      console.error(error);
    }

    commit("setEventsLoading", false);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
