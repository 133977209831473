import { objToUrlParam } from "@/shared/utils";
import axios from "axios";

export const getAll = payload => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/events?${objToUrlParam(payload)}`
  );
};

export const getTypes = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/events/types`);
};

export const create = payload => {
  return axios.post(`${process.env.VUE_APP_API_URL}/events`, payload);
};
