import { MODEL_ACTION } from "@/shared/constants";
import store from "@/store";
import _ from "lodash";

export default (to, from, next) => {
  const model = _.get(to, "params.model");
  const page = store.getters.getPage({ model });
  const action =
    _.get(to, "meta.kind") === MODEL_ACTION.CREATE
      ? MODEL_ACTION.CREATE
      : MODEL_ACTION.EDIT;

  if (!page || !_.includes(_.get(page, "actions", []), action)) {
    store.dispatch(
      "showSnackbar",
      `You don't have permission to ${action} a ${model}.`
    );
    next(`/app/models/${model}`);
  }

  next();
};
