import axios from "axios";

export const getAll = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/articles`);
};

export const create = payload => {
  return axios.post(`${process.env.VUE_APP_API_URL}/articles`, payload);
};

export const update = (id, payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/articles/${id}?_method=PUT`, payload);
};

export const remove = id => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/articles/${id}`);
};
export const preview = (id = null, payload, lang) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/articles/preview`, payload, {
    params: { id, lang }
  });
};
