import axios from "axios";

export const getAll = id => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/information-items?iid=${id}`
  );
};

export const getById = id => {
  return axios.get(`${process.env.VUE_APP_API_URL}/information-items/${id}`);
};

export const create = payload => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/information-items`,
    payload
  );
};

export const update = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/information-items/${id}?_method=PUT`,
    payload
  );
};

export const reorder = payload => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/information-items/reorder`,
    payload
  );
};

export const remove = id => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/information-items/${id}`);
};
